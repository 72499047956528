import request from '@/utils/request'
import { param2Obj } from '@/utils'

// 直播
export function getLiveList(data) {
  return request({
    url: '/liveapi/live/pageliving',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getLiveGoods(data) {
  return request({
    url: '/liveapi/live/pull/goods/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchLiveNotice(data) {
  return request({
    url: '/liveapi/live/pull/watch/notice',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchLive(data) {
  return request({
    url: '/liveapi/live/pull/watch/url',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchLivePlayback(data) {
  return request({
    url: '/liveapi/live/pull/watch/back',
    method: 'post',
    data: param2Obj(data)
  })
}
