<template>
	<div class="mpage-home" v-cloak>
		<div class="mhome-head" v-if="liveInfo.aliyun_status==1 && liveInfo.status==1 && liveInfo.standard_room_jump_url">
			<iframe :src="liveInfo.standard_room_jump_url" allowfullscreen="true"></iframe>
		</div>
		<div class="mhome">
			<div class="mhome-section mhome-head" v-if="liveInfo.aliyun_status!==1">
				<img v-if="liveInfo.cover0" :src="liveInfo.cover0" alt="" class="live-cover">
				<div class="mlive-user">
					<div class="user-info">
						<img src="@/assets/images/item/live-avatar-100-100.png" alt="" class="avatar">
						<div class="item-box">
							<div class="name">{{liveInfo.title}}</div>
							<div class="text">{{$t('mobile.live.text7')}} {{storeInfo.count_fans}}</div>
						</div>
					</div>
					<div class="sidebar">
						<div class="btn disabled" @click="handleUnFollow" v-if="liveInfo.is_collect==1">{{$t('mobile.actions.unfollow')}}</div>
						<div class="btn" @click="handleFollow" v-else>{{'+'+$t('mobile.actions.follow')}}</div>
						<div class="text">{{$t('mobile.live.text8')+':'}} {{liveInfo.live_id?liveInfo.live_id:$route.params.id}}</div>
					</div>
				</div>
				<div class="mlive-status">
					{{liveInfo.aliyun_status==2 || liveInfo.status==2?$t('mobile.live.text9'):$t('mobile.live.text10')}}
				</div>
			</div>
			<div class="mhome-section">
				<div class="sction-title">
					<span class="name">{{$t('mobile.live.text4')}}</span>
					<router-link :to="{name: 'mLiveGoods',params:{id:$route.params.id}}" class="next">{{$t('mobile.actions.moreBaby')}}<i class="el-icon-arrow-right"></i></router-link>
				</div>
				<div class="mgoods-list">
					<router-link class="item" v-for="(item,index) in liveGoods" :key="index" :to="{name:'mGoodsInfo',params:{id:item.goodsId},query:{live:liveInfo.live_id}}">
						<div class="item-pic"><img :src="item.defaultImage" @error="imageLoadErrorGoods" alt=""></div>
						<div class="item-main">
							<div class="name">{{item.goodsName}}</div>
							<div class="price">￥<span>{{item.price}}</span></div>
						</div>
					</router-link>
				</div>
			</div>
			<div class="mhome-section">
				<div class="sction-title">
					<span class="name">{{$t('mobile.live.text11')}}</span>
					<router-link :to="{name: 'mLiveListIndex'}" class="next">{{$t('mobile.live.text12')}}<i class="el-icon-arrow-right"></i></router-link>
				</div>
				<div class="mlive-list">
					<router-link class="item" v-for="(item,index) in liveList" :key="index" :to="{name:'mLiveInfo',params:{id:item.live_id}}">
						<img :src="item.cover0" @error="imageLoadErrorLive" alt="" class="pic">
						<div class="item-main">
							<div class="name">{{item.title}}</div>
							<div class="text">
								<el-tag type="danger" effect="dark">{{$t('mobile.live.tag2')}}</el-tag>
								<span>{{item.views}} {{$t('mobile.tags.views')}}</span>
							</div>
						</div>
					</router-link>
				</div>
			</div>
		</div>
		<div class="mpop-down" v-if="flagDown">
			<div class="mpop-down-wrap">
				<ul>
					<li><el-button type="danger" @click="doApp" round>{{$t('mobile.actions.openApp')}}</el-button></li>
					<li><el-button type="danger" @click="handleDownApp" plain round>{{$t('mobile.actions.downApp')}}</el-button></li>
				</ul>
				<div class="mpop-down-close" @click="flagDown = false"><i class="el-icon-circle-close"></i></div>
			</div>
		</div>
	</div>
</template>
<script>
	import CallApp from "callapp-lib";
	import { fetchShopInfo, onCollect, onUncollect, browseStore } from '@/api/mobile/store';
	import { getLiveList, getLiveGoods, fetchLive } from '@/api/mobile/live';
	import { getToken } from '@/utils/auth';
	export default {
		data() {
			return {
				liveInfo: {
					count_fans: 0,
					aliyun_status: 0,
				},
				storeInfo: {},
				liveGoods: [],
				liveList: [],
				flagDown: false,
			}
		},
		mounted() {
			this.getLiveInfo();
			this.getLiveGoods();
			this.getLivelist();
			// if(document.documentElement.clientWidth<768){
			// 	this.flagDown = true;
			// }
			// document.querySelector('body').setAttribute('style','overflow:hidden');
		},
		methods: {
			imageLoadErrorGoods(e) {
				e.target.src = this.$imageErrorGoods;
			},
			imageLoadErrorLive(e) {
				e.target.src = this.$imageErrorLive;
			},
			getLiveInfo(){
				let param = {
					live_id: this.$route.params.id,
					app_key: this.$store.getters.appKey
				}
				fetchLive(param).then(response => {
					const { data } = response
					this.liveInfo = data
					setTimeout(async () => {
						await browseStore({ live_id: data.live_id, store_id: data.store_id, from: 1 })
					},3000)
					this.getStoreInfo()
				}).catch(() => {
					this.$router.push({name:'mLiveListIndex'});
				})
			},
			getStoreInfo(){
				const id = this.liveInfo.store_id;
				fetchShopInfo({store_id: id}).then(response => {
					this.storeInfo = response.data;
				})
			},
			getLiveGoods(){
				let param = {
					page_index: 0,
					page_size: 4,
					live_id: this.$route.params.id,
					app_key: this.$store.getters.appKey
				}
				getLiveGoods(param).then(response => {
					this.liveGoods = response.data.list;
				})
			},
			getLivelist(){
				let param = {
					isall: 1,
					issubscribe: 1,
					page_index: 0,
					page_size: 4,
					live_id: this.$route.params.id,
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey
				}
				getLiveList(param).then(response => {
					this.liveList = response.data.list;
				})
			},
			handleFollow(){
				const hasToken = getToken()
				if(!hasToken){
					this.$message({
						message: this.$t('mobile.errorMsg.login'),
						customClass: 'wap-message',
						type: 'warning',
						duration: 1500,
						onClose:()=>{
							window.location.href = this.$loginBasePath+'/m/?jump_url='+window.location.href;
						}
					});
					return false;
				}
				let param = {
					type: 'live',
					followId: this.$route.params.id,
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey
				}
				onCollect(param).then(() => {
					this.$message({
						message: this.$t('mobile.successMsg.follow'),
						customClass: 'wap-message',
						type: 'success'
					});
					// this.getStoreInfo();
					this.$router.go(0)
				})
			},
			handleUnFollow(){
				const hasToken = getToken()
				if(!hasToken){
					this.$message({
						message: this.$t('mobile.errorMsg.login'),
						customClass: 'wap-message',
						type: 'warning',
						duration: 1500,
						onClose:()=>{
							window.location.href = this.$loginBasePath+'/m/?jump_url='+window.location.href;
						}
					});
					return false;
				}
				let param = {
					type: 'live',
					followId: this.$route.params.id,
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey
				}
				onUncollect(param).then(() => {
					this.$message({
						message: this.$t('mobile.cancelMsg.follow'),
						customClass: 'wap-message',
						type: 'warning'
					});
					// this.getStoreInfo();
					this.$router.go(0)
				})
			},
			doApp(){
				let u = navigator.userAgent;
				let ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
				if(!ios){
					let options = {
						scheme: {
							protocol: 'uulian'
						},
						intent: {
							package: 'com.uulian.uuanchor',
							scheme: 'uulian',
						},
						appstore: '', //app store地址
						fallback: '', // 唤端失败后跳转地址
					}
					const callLib = new CallApp(options);
					callLib.open({
						path: '',
						param: {
							live_id: this.$route.params.id
						}
					});
				}else {
					this.$message({
						message: this.$t('mobile.errorMsg.supportApp'),
						customClass: 'wap-message',
						type: 'error'
					});
				}
			},
			handleDownApp(){}
		},
		watch: {
			'$route': {
				handler: function(val, oldVal) {
					Object.assign(this.$data, this.$options.data());
					this.getLiveInfo();
					this.getLiveGoods();
					this.getLivelist();
				},
				deep: true
			},
		}
	}
</script>
<style lang="scss" scoped>
	.mpage-home {
		height: 100%;
		overflow-y: auto;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		.mhome-head {
			width: 100%;
			height: calc(100vh - 120px);
			position: relative;
			// margin-bottom: 0.32rem;
			iframe {
				width: 100%;
				height: 100%;
				border: none;
				background-color: #eceef3;
			}
			.live-cover {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			.mlive-user {
				top: 0;
				width: 100%;
				padding: 0.384rem;
				position: absolute;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.user-info {
					display: flex;
					padding: 0.128rem 0.512rem 0.128rem 0.128rem;
					border-radius: 0.736rem;
					background-color: rgba(0,0,0,.25);
					box-shadow: 0 0 0.352rem rgba(255,255,255,.5);
					.avatar {
						width: 1.216rem;
						height: 1.216rem;
						border-radius: 50%;
					}
					.item-box {
						max-width: 6.4rem;
						margin-left: 0.192rem;
						color: #FFFFFF;
						.name {
							font-size: 0.416rem;
							height: 0.64rem;
							line-height: 0.64rem;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						.text {
							font-size: 0.384rem;
							line-height: 0.576rem;
						}
					}
				}
				.sidebar {
					text-align: center;
					.btn {
						height: 0.768rem;
						line-height: 0.768rem;
						border: 1px solid #f40;
						color: #f40;
						display: inline-block;
						font-size: 0.416rem;
						padding: 0 0.256rem;
						border-radius: 0.416rem;
					}
					.btn.disabled {
						color: #999999;
						border-color: #999999;
					}
					.text {
						color: #FFFFFF;
						font-size: 0.384rem;
						line-height: 0.512rem;
						margin-top: 0.128rem;
					}
				}
			}
			.mlive-status {
				top: 50%;
				left: 50%;
				color: #FFFFFF;
				font-size: 0.576rem;
				position: absolute;
				white-space: nowrap;
				transform: translateX(-50%);
				line-height: 1.5;
				padding: 0.256rem 0.64rem;
				border-radius: 0.704rem;
				background-color: rgba(0,0,0,.25);
				box-shadow: 0 0 0.352rem rgba(255,255,255,.5);
			}
		}
		.mhome {
			height: auto;
			.mhome-section {
				margin-bottom: 0.32rem;
				background-color: #FFFFFF;
				.sction-title {
					height: 1.28rem;
					padding-right: 0.384rem;
					border-left: 0.128rem solid red;
					background-color: #FFFFFF;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.name {
						font-size: 0.448rem;
						padding-left: 0.256rem;
					}
					.next {
						color: red;
						font-size: 0.416rem;
						i {
							margin-left: 0.128rem;
						}
					}
				}
			}
			.mgoods-list {
				overflow: hidden;
				.item {
					float: left;
					width: 50%;
					.item-pic {
						width: 100%;
						height: 6rem;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					.item-main {
						padding: 0.32rem;
						.name {
							color: #333333;
							font-size: 0.416rem;
							height: 1.088rem;
							line-height: 0.544rem;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
						}
						.price {
							color: #f40;
							font-size: 0.352rem;
							height: 0.544rem;
							line-height: 0.544rem;
							span {
								font-size: 0.448rem;
							}
						}
					}
				}
			}
			.mlive-list {
				overflow: hidden;
				.item {
					width: 50%;
					float: left;
					position: relative;
					.pic {
						width: 100%;
						height: 6rem;
						object-fit: cover;
					}
					.item-main {
						left: 0;
						bottom: 0;
						width: 100%;
						padding: 0.32rem;
						box-sizing: border-box;
						position: absolute;
						.name {
							color: #FFFFFF;
							font-size: 0.416rem;
							height: 0.544rem;
							line-height: 0.544rem;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							margin-bottom: 0.128rem;
						}
						.text {
							color: #FFFFFF;
							font-size: 0.384rem;
							line-height: 0.544rem;
							.el-tag {
								border: none;
								height: 0.512rem;
								line-height: 0.512rem;
								font-size: 0.32rem;
								padding: 0 0.192rem;
								border-radius: 0.256rem;
								margin-right: 0.192rem;
							}
						}
					}
				}
			}
		}
	}
	.mpage-home::-webkit-scrollbar {
		display: none;
	}
	.mpop-down {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: rgba(0,0,0,.3);
		.mpop-down-wrap {
			top: 50%;
			left: 50%;
			width: 9.6rem;
			position: relative;
			background-color: #FFFFFF;
			transform: translate3d(-50%,-50%,0);
			padding: 0.64rem;
			border-radius: 0.256rem;
			box-sizing: border-box;
			li {
				margin-bottom: 0.512rem;
				text-align: center;
			}
			li:last-child {
				margin-bottom: 0;
			}
			::v-deep .el-button {
				height: 1.28rem;
				width: 100%;
				border-radius: 0.64rem;
			}
		}
		.mpop-down-close {
			left: 50%;
			top: 100%;
			margin-top: 0.64rem;
			color: #FFFFFF;
			font-size: 1.28rem;
			position: absolute;
			transform: translateX(-50%);
		}
	}
</style>